import React from "react"
import { StaticQuery } from "gatsby";
import Layout from "../components/layout"
import SEO from "../components/seo"

const ContainerStyles = {
  padding: "0",
  margin: "0",
  listStyle: "none",
  display: "-webkit-box",
  display: "-moz-box",
  display: "-ms-flexbox",
  display: "-webkit-flex",
  display: "flex",
  WebkitFlexFlow: "row wrap",
  justifyContent: "space-around"
}

const ItemStyle = {
  padding: "5px",
  margin: "10px",
  textAlign: "center",
}

class Product extends React.Component {

  componentDidMount() {
    this.stripe = window.Stripe('pk_test_ibskNGH9Km2Ec42oih49SLGh', {
      betas: ['checkout_beta_4']
    })
  }

  async redirectToCheckout(id, e) {
    e.preventDefault()
    const selfUrl = process.env.BASE_URL
    const { error } = await this.stripe.redirectToCheckout({
      items: [{ sku: id, quantity: 1 }],
      // items: [
      //   { sku: "sku_EkloyqPXtWDQ2W", quantity: 1 },
      //   { sku: "sku_Ekhj9M5euDd44O", quantity: 1 }
      // ],

      // successUrl: `${selfUrl}/success/`,
      // cancelUrl: `${selfUrl}/cancel`,

      successUrl: `https://brave-hamilton-1233f8.netlify.com/success/`,
      cancelUrl: `https://brave-hamilton-1233f8.netlify.com/cancel`,

    })

    if (error) {
      console.warn("Error:", error)
    }
  }

  render() {
    const { id, currency, price, name, image } = this.props;
    const priceFloat = (price/100).toFixed(2)
    const formattedPrice = new Intl.NumberFormat('en-GB', { style: 'currency', currency }).format(priceFloat)

    return (
      <form style={ItemStyle} onSubmit={e => this.redirectToCheckout(id, e)}>
        <img  style={{
          display: "block",
          height: "200px",
          margin: "0 auto"
        }} src={image} />
        <h2>{name} - {formattedPrice}</h2>
        <button type="submit">Buy Now</button>
      </form>
    )
  }

}

export default () => (
  <StaticQuery
    query={graphql`
      {
        allStripeSku {
          edges {
            node {
              id
              currency
              price
              image
              attributes {
                name
              }
            }
          }
        }
      }
    `}
    render={data => (
      // console.log(data)
      <Layout>
        <SEO title="Products" keywords={[`gatsby`, `application`, `react`]} />
        <div style={ContainerStyles}>
          {data.allStripeSku.edges.map( ({ node: sku }) => (
            <Product
              key={sku.id}
              id={sku.id}
              currency={sku.currency}
              price={sku.price}
              name={sku.attributes.name}
              image={sku.image}
            />
          ))}
        </div>
      </Layout>
    )}
  />
)